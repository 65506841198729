.detailsParagraph {
    color: #fff;
  font-weight: "400";
  margin-top: 10px;
  font-size: 14px;
}

.title {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
  font-size: large;
}

.detailsTextContainer {
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
}

.paragraphContainer {
  flex: 1;
}
