.logged {
  margin-top: -45;
  font-size: 15;
  color: white;
  font-weight: "300";
  margin-left: 27px;
}

.selectValue {
  background-color: black;
  margin-bottom: 18px;
  border: burlywood;
  color: white;
  margin-left: auto;
  margin-right: auto;
  font-family: auto;
  font-size: large;
  font-weight: bold;
}

.latestRec {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.mainContainer {
  min-height: unset !important;
  text-align: right;
  margin-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
}

.safeContainer {
  flex: 1;
  width: 100%;
  margin-left: 75px;
  padding-left: 15px;
  flex-direction: column;
  margin-top: 60px;
  background-color: #383838;
}
