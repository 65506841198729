.paragraph-records {
  color: white !important;
  font-size: large;
  font-family: auto;
  height: 40px;
  margin-top: 10px;
  font-weight: 600;
}
.paragraph2 {
  color: var(--gold) !important;
  margin-top: -30px;
  font-size: large;
  font-family: auto;
  font-weight: 500;
}

.paragraphTime {
  color: white !important;
  margin-top: -23px;
  font-size: inherit;
  font-family: auto;
  margin-bottom: 26px;
  font-weight: 600;
}
  .indicatortTitle {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-top: 40px;
    font-family: sans-serif;
  }
  
  .itemContainer {
    background-color: #383838;
    margin-left: -52px;
  }
  
  .mainContainerDayMultiple {
    background-color: black;
    width: 88%;
  
  }
  .recordContainerMultiple {
    margin-left: 38px;
  }
  
  .indicatorsHeader {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 30px;
    background-color: #000;
    align-items: center;
    justify-content: center;
  }
  
  .headerIndicator {
    color: #d5bb64;
    font-size: small;
    margin-left: 40px;
    flex: 1 1 auto;
  }
  
  .headerValue {
    color: white;
    font-size: 13px;
    flex: 2;
  }
  
  .indicatorsContainer {
    height: 600px;
  }
  
  .indicatorContainer:nth-of-type(even) {
    background-color: #000;
  }
  
  .indicatorContainer:nth-of-type(odd) {
    background-color: #413939;
  }
  
  .indicatorContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    height: 50px;
    border: 1px solid;
  }
  
  .indicatorKey {
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    margin-left: 40px;
    flex: 3;
  }
  
  .indicatorValue {
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    flex: 2;
  }
  
.selectValueDay {
  background-color: black;
  margin-bottom: 18px;
  border: burlywood;
  color: white;
  margin-left: 34px;
  font-family: auto;
  font-size: revert;
  width: 159px;
  font-weight: bold;
}

.optionDay {
  color: white;
}

  .bottomPage {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 300px;
    background-color: #000;
  }
  
  .addButton {
    flex: 1;
  }
  
  .addContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 100px;
    height: 20px;
    margin-left: 50px;
    align-items: center;
    justify-content: center;
  }
  
  .addText {
    flex: 1;
    font-size: 13px;
    color: #fff;
  }
  
  #popup {
    display: flex;
    flex-direction: column;
    flex: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    justify-content: center;
      overflow:auto;
      border: 1px solid red;
      z-index: 1000;
    height: 100vh;
  }

  .days-containers {
    margin-top: 15px;
  }

  .day-row-container{
    height: 93px;
    margin-bottom: 30px;
  }

  .athlete-name {
    margin-bottom: 0px;
    color: var(--gold);
    font-size: large;
    font-weight: 600;
    margin-left: 40px;
    flex: 1 1 auto;

  }

  .time-paragraph{
    margin-bottom: 0px;
    color: white;
    font-size: small;
    margin-left: 40px;
    flex: 1 1 auto;
    font-weight: 700;
  }

  .indicator-value{
    color: white;
    font-size: large;
    margin-top: 5px;
    font-weight: 600;
    font-family: auto;
  }
  