@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro";
  background-color: black;
  height: 100%;
  display: flex;
}
body #root {
  width: 100%;
}

span {
  font-size: 14px;
}

.ant-btn > span {
  display: inline-block;
  font-family: "Source Sans Pro";
}

.ant-btn .anticon {
  color: black;
  font-size: 20px;
}

.ant-input-number-handler-wrap {
  visibility: hidden;
}

.ant-layout-header {
  display: flex;
  justify-content: normal !important;
  align-items: center;
  background-color: var(--black);
  padding: 0 !important;
  border-bottom: 1px solid #262626;
}

@media screen and (max-width: 800px) {
  .ant-layout-header {
    text-align: center;
    justify-content: center !important;
  }
}

.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  background-color: rgb(38, 38, 38);
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px;
}
.ant-collapse-content {
  background-color: #383838;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: white;
  line-height: 1.5715;
  transition: all 0.3s, visibility 0s;
}

h1 {
  overflow: hidden;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.2em;
  margin-left: 5%;
  margin-right: 5%;
  color: #fff;
}
h1:before,
h1:after {
  background-color: #fff;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}
h1:before {
  color: #fff;
  right: 0.5em;
  margin-left: -50%;
}
h1:after {
  color: #fff;
  left: 0.5em;
  margin-right: -50%;
}

a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  label,
  .ant-select-selection-placeholder {
    font-size: 10px !important; /* The width is 100%, when the viewport is 800px or smaller */
  }
}

@media screen and (max-width: 800px) {
  .ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    font-size: 14px !important;
    right: 11px;
    left: 11px;
    transform: translateY(-50%);
    transition: all 0.3s;
  }
}

@media screen and (max-width: 800px) {
  thead.ant-table-thead {
    display: none;
  }
  td.ant-table-cell {
    padding-top: 6%;
  }
}

@media screen and (max-width: 800px) {
  td.ant-table-cell {
    padding-top: 6% !important;
  }
}

@media screen and (max-width: 800px) {
  span.ant-select-selection-placeholder {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 800px) {
  input.ant-input {
    font-size: 14px !important;
  }
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background-color: var(--gold);
  border: 3px solid transparent;
  border-radius: 4px;
}

@media screen and (max-width: 800px) {
  .athleteName {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 800px) {
  .ant-col-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 100%;
  }
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 17px;
  }
}

@media screen and (max-width: 800px) {
  .ant-layout-sider {
    flex: 0 0 30% !important;
    min-width: 0px !important;
  }
}

/*@media screen and (max-width: 800px) {
  .ant-table-thead > tr > th {
    font-size: 10px !important;
  }
}*/

@media screen and (max-width: 800px) {
  .ant-table-tbody > tr {
    text-align: center;
  }
}

.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
}

@media screen and (max-width: 800px) {
  .ant-table-tbody > tr > td {
    text-align: center;
    line-height: 6px;
  }
}

.selectContainer {
  text-align: left;
  margin-bottom: 3%;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 800px) {
  .selectContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 0px 11px;
  overflow-wrap: break-word;
}

.ant-row {
  align-items: center;
}

@media screen and (max-width: 800px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    position: relative;
    padding: 19px 15px;
    padding-top: 9% !important;

    overflow-wrap: break-word;
  }
}

@media screen and (max-width: 800px) {
  .ant-select-multiple .ant-select-selection-item-content {
    display: inline-block;
    overflow: hidden;
    white-space: pre;
    font-size: xx-small;
    text-overflow: ellipsis;
  }
}

.selectSubContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 800px) {
  .selectSubContainer {
    display: flex;
    flex-direction: row;
  }
}

.selector {
  flex: 1;
  margin-right: 2%;
}

@media screen and (max-width: 800px) {
  .selector {
    width: 34%;
    margin-right: 2%;
    margin-bottom: 3%;
  }
}

.athletesCount {
  padding-left: 4%;
  color: #d5bb64;
  background-color: black;
  border: 0px;
  flex: 1;
  text-align: left;
  border-radius: 2px;
}

@media screen and (max-width: 800px) {
  .athletesCount {
    width: 50%;
    margin-right: 2%;
    margin-bottom: 3%;
  }
}

@media (max-width: 575px) {
  .login_overlay .ant-form .ant-form-item .ant-form-item-label {
    height: 21px !important;
  }
}

.session_form
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "*" !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: inline-block;
  margin-right: 4px;
  color: #d5bb64;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

@media (max-width: 800px) {
  .ant-layout-header img {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .settings .ant-form-item-control-input-content {
    flex: auto;
    max-width: 88% !important;
  }
}

.login_overlay .ant-form-vertical .ant-form-item {
  flex-direction: column;
}

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  bottom: 103%;
  /* right: -36px; */
  z-index: 1;
  width: 62px;
  /* height: 42px; */
  color: #fff;
  /* line-height: 42px; */
  text-align: center;
  background: black;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: "background 0.3s ease";
}

.ant-layout-sider-zero-width-trigger .anticon {
  display: inline-block;
  color: inherit;
  font-size: 22px !important;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-form .ant-form-item .ant-form-item-control {
  flex: 3 !important;
}

@media (max-width: 575px) {
  .ant-form-vertical .ant-form-item-label > label {
    font-size: 14px !important;
  }
}

.login_overlay .ant-form-vertical .ant-form-item {
  flex-direction: row !important;
}

.ant-col .ant-form-item-control {
  width: 75%;
}

.ant-form-item-control-input {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 575px) {
  .athlete-modal .ant-modal-body {
    padding: 7px 16px 16px 30px !important;
  }
}

@media screen and (max-width: 800px) {
  .detailsParagraph {
    font-size: 8px;
  }
}

@media screen and (max-width: 800px) {
  .settingsContent {
    text-align: center;
    flex: 1;
    max-width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .ant-radio-wrapper {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 800px) {
  .settingsContainer {
    flex-direction: column;
  }
}

.selectLanguage {
  width: 20%;
  margin-right: 2%;
}

@media screen and (max-width: 800px) {
  .selectLanguage {
    width: 40% !important;
  }
}

@media screen and (max-width: 800px) {
  .ant-radio-inner {
    width: 10px !important;
    height: 10px !important;
  }
}

@media screen and (max-width: 800px) {
  .ant-picker-input > input {
    font-size: 10px !important;
  }
}

.titleEdit {
  margin-bottom: 4%;
}

.ant-form-item-control-input {
  min-height: 0%;
}

.ant-form {
  padding-right: 0px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  text-align: left;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #d5bb64;
  position: relative;
  background-color: black;
  border: 0px;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.gu-transit {
  height: 0px;
  color: #ff4d4f !important;
  border: 1px solid red;
  background-color: #d5bb64;
}
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
  height: inherit !important;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */

.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title-selected {
  background-color: black;
}

.ant-modal-title {
  overflow: hidden;
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.2em;
  margin-right: 5%;
  color: #fff;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  background-color: black;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #d5bb64 !important;
  color: black !important;
  font-size: 20px !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px;
}
.athlete-modal .titleEdit:before,
.athlete-modal .titleEdit:after {
  background-color: #fff;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.athlete-modal .titleEdit:before {
  color: #fff;
  right: 0.5em;
  margin-left: -50%;
}

.athlete-modal .titleEdit:after {
  color: #fff;
  left: 0.5em;
  margin-right: -50%;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  background-color: transparent;
}

.ant-select-selection-placeholder {
  color: #d5bb64;
}

.ant-select-multiple .ant-select-selection-item {
  color: black;
  background-color: #d5bb64;
  line-height: normal;
  height: auto;
  border: 1px solid #d5bb64;
}

@media screen and (max-width: 800px) {
  .ant-select-multiple .ant-select-selection-item {
    font-size: small;
  }
}

@media screen and (max-width: 800px) {
  .session_form .ant-input {
  }
}

@media screen and (max-width: 800px) {
  .ant-modal-title {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 800px) {
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .anticon {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 800px) {
  .ant-menu-vertical .ant-menu-submenu {
    margin-top: 25%;
  }
}

/* button -------------------------------------------------------- */
.ant-btn,
.ant-btn:focus,
.ant-btn:active {
  background-color: var(--gold);
  border: 1px solid var(--gold);
  color: #d5bb64;
  border-radius: 4px;
  height: auto;
}
.ant-btn:hover {
  background-color: transparent !important;
  border: 1px solid var(--gold_hover) !important;
  color: #d5bb64;
}

.ant-btn,
.ant-btn:focus,
.ant-btn:active {
  background-color: transparent;
}

.session_form .ant-form-item-label > label {
  margin-right: 15%;
}

.ant-collapse-header-text {
  text-align: left;
}

.indicators-table thead.ant-table-thead {
  display: contents;
}

.indicators-table .ant-table-tbody > tr > td {
  line-height: normal;
}

.indicators-table {
  margin-left: 4%;
  margin-right: 4%;
}

.indicators-table .ant-table-thead > tr > th {
  position: relative;
  text-align: center;
  overflow-wrap: break-word;
}

.table-indicators .ant-table-thead > tr > th {
  cursor: pointer;
}

.indicators-table .ant-table-tbody > tr > td {
  padding: 13px 6px;
}

.login-form .ant-btn:hover {
  background-color: transparent !important;
  border: 1px solid var(--gold_hover) !important;
  color: #d5bb64;
}

.ant-btn.success {
  background-color: var(--green);
  border: 1px solid var(--green);
}
.ant-btn.success:hover {
  background-color: var(--green_hover);
  border: 1px solid var(--green_hover);
}

.ant-btn.error {
  background-color: var(--red);
  border: 1px solid var(--red);
}
.ant-btn.error:hover {
  background-color: var(--red_hover);
  border: 1px solid var(--red_hover);
}

/* header -------------------------------------------------------- */
.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--black);
  padding: 0 !important;
  border-bottom: 1px solid #262626;
}

.ant-table-thead > tr > th {
  background-color: black;
  color: #d5bb64;
  border-bottom: 0px;
  font-size: 14px;
}

.settingsContent {
  margin-left: 5%;
  font-size: 14px;
}

.ant-table-tbody > tr:hover > td {
  background-color: transparent !important;
  color: white;
}

.ant-pagination-item-active a {
  background-color: black;
  color: #d5bb64;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: black;
}

.anticon {
  color: #d5bb64;
}

.ant-table-tbody > tr > td {
  border-bottom: 0px;
  font-size: small;
}

.ant-table-tbody > tr:nth-child(even) {
  background-color: #352f2f;
  color: white;
}

.ant-table-tbody > tr:nth-child(odd) {
  background-color: #242222;
  color: white;
}

.settingsContainer:nth-child(even) {
  background-color: #352f2f;
  color: white;
}

.settingsContainer:nth-child(odd) {
  background-color: #242222;
  color: white;
}

.ant-radio-wrapper {
  color: white;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #d5bb64;
}

.ant-radio-inner::after {
  background-color: #d5bb64;
}

.table-row-light {
  background-color: #413939;
}

.table-row-dark {
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  background-color: black;
}

.ant-menu-submenu-arrow {
  color: white;
}

.ant-layout-content {
  background-color: #262626;
}

.ant-upload-list-picture-card
  .ant-upload-list-item-uploading.ant-upload-list-item {
  height: 84%;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: black;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
  margin-right: 30%;
  margin-top: 18%;
}

.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: fit-content !important;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
justify-content: normal;
  height: 100%;
  text-align: center;
}

.ant-progress-router {
  display: none
}

.ant-progress-inner {
  display: none
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  border-radius: 50%;
  height: 62%;
  width: 62%;
  margin-top: 23%;
}

@media screen and (max-width: 800px) {
  .ant-upload-picture-card-wrapper {
    display: block;
    width: -moz-fit-content !important;
    width: 17% !important;
  }
}

.ant-upload-picture-card-wrapper {
  display: block;
  width: -moz-fit-content !important;
}

.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  /* background-color: #fafafa; */
  border: 1px solid #000;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  /* height: 100%; */
  background-color: transparent;
  opacity: 0;
  transition: all 0.3s;
  content: " ";
}

.ant-upload-list-picture-card
  .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #000;
  color: #d5bb64;
}

.ant-upload {
  background-color: black;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #d5bb64;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  outline: 0;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  border-radius: 50%;
}

.mainContainer {
  min-height: unset !important;
}

.ant-layout-header.ant-menu-horizontal {
  background-color: var(--dark_blue);
}
.ant-layout-header .ant-menu-item-selected,
.ant-layout-header .ant-menu-item-selected,
.ant-layout-header .ant-menu-horizontal .ant-menu-item:hover {
  background-color: var(--gold);
}

.ant-layout-header img {
  width: 200px;
}

.ant-menu-title-content {
  color: white;
}

/* debug header -------------------------------------------------- */
.debug_mode_header {
  background-color: red;
  color: white;
  font-weight: bold;
  height: 40px;
  font-size: 25px;
  justify-content: center;
}

/* layout -------------------------------------------------------- */
.ant-layout {
  height: 100%;
}

/* loader -------------------------------------------------------- */
.ant-spin {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-spin-dot-item {
  background-color: var(--gold);
  font-size: 30px;
}

.ant-spin-text {
  color: var(--gold);
  margin-left: 20px;
  font-size: 30;
}

/* font-size ----------------------------------------------------- */
body {
  font-size: 16px;
}

.ant-table {
  font-size: 16px;
}
.ant-picker-input > input,
.ant-select {
  font-size: 14px !important;
  color: #d5bb64 !important;
}

.ant-pagination-item a {
  background-color: black;
  color: #d5bb64;
}

/*ant modal ---------------------------------*/

.ant-modal-body {
  background-color: #181818;
  padding: 0px;
}

.athlete-modal .ant-modal-header {
  background-color: #181818;
  border-bottom: 0px;
  margin-left: 10%;
  padding-left: 0px;
  text-align: center;
  margin-right: 10%;
  padding-right: 0px;
  padding-top: 5%;
}

.ant-form {
  padding-right: 12%;
}

.ant-input-number {
  background-color: black;
  border: none;
  border-radius: 11px;
  width: 100%;
}

.ant-input-number-input {
  width: 100%;
  border-radius: 11px;
  background-color: black;
  color: white;
}

.ant-form-vertical .ant-form-item-label > label {
  height: 100%;
}

.ant-modal-header {
  background-color: #181818;
  border-bottom: 0px;
  padding-top: 10%;
  padding-bottom: 1%;
  margin-left: 10%;
  padding-left: 0px;
  text-align: center;
  margin-right: 10%;
  padding-right: 0px;
  border-bottom: 1.6px solid #352f2f;
}

.ant-input-affix-wrapper {
  background: black;
  border-color: #d5bb64;
}

.ant-input-number-handler-wrap {
  background: black;
  border-color: #d5bb64;
}

.ant-input-number-handler {
  border-left: 1px solid #d5bb64;
}

.login_form .login_input_text .ant-input-affix-wrapper,
.login_form .login_input_text .ant-input-affix-wrapper input {
  background-color: black !important;
  color: #fff;
}

input:-internal-autofill-selected {
  background-color: black !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover {
  background: black;
  border-color: #ff4d4f;
}

.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: black;
  border-color: #ff4d4f;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.ant-select-clear {
  background: black;
}

.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: black;
  border-color: #d5bb64;
}

.ant-input-password-icon.anticon {
  color: #d5bb64;
}

.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: transparent;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  background-color: black;
  margin-top: 0px !important;
  width: auto;
  margin-bottom: 0px !important;
}

.ant-empty-description {
  color: white;
}

.ant-modal-footer {
  background-color: #181818;
  display: flex;
  margin-left: 7%;
  margin-right: 7%;
  padding-bottom: 7%;
  padding-top: 7%;
  border-top: 0px;
}

.ant-btn-default {
  flex: 1;
  background-color: transparent;
  border-color: #d5bb64;
}

.ant-btn-primary {
  flex: 1;
  background-color: transparent;
  color: #d5bb64;
  border: 1px solid #d5bb64;
}

.ant-modal-title {
  color: white;
  margin-bottom: 0%;
}

.athlete-modal .ant-modal-body {
  padding: 32px 16px 16px 40px;
}

.ant-modal-close {
  color: white;
}

.ant-modal-content {
  width: 85%;
  margin: auto;
  background-color: #181818;
}

.session_form .ant-input {
  background-color: black;
  color: white;
  border-radius: 11px;
  height: 100% !important;
}

.settings .ant-form-item-control-input-content.session-form-button {
  text-align: center;
}

.settings .ant-form-item-control-input-content {
  flex: auto;
  max-width: 50%;
}

.session_form .ant-btn {
  text-align: center;
  width: auto;
  height: auto;
  text-transform: none;
  font-size: 14px;
  background-color: transparent;
  color: #d5bb64;
  border: 1px solid #d5bb64;
}

.ant-input {
  border: 0px;
  color: white !important;
}

.ant-icon,
.ant-icon-delete {
  font-size: 14px !important;
}

.ant-picker {
  background-color: black;
  border: 0px;
  border-radius: 11px;
}

.ant-picker-input > input,
.ant-select {
  font-size: 16px;
  color: white;
}

.ant-picker-input {
  position: relative;
  background-color: black;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.ant-form-vertical .ant-form-item {
  flex-direction: row;
}

.ant-form-vertical .ant-form-item-label {
  flex: 1;
}

.ant-form-item {
  font-size: 8px;
  margin-bottom: 4px;
}

.ant-form-item-control {
  flex: 2;
}

.session_form .ant-form-item-label > label {
  text-transform: none;
}

.ant-input:placeholder-shown {
  color: #4d4e4e !important;
}

input[type="password"]:focus {
  background-color: black;
}
/* -------------- APEX CHART ----------------------*/

#apex-indicators {
  width: auto;
  height: auto;
  margin-top: 20%;
  text-align: -webkit-center;
}

#alert-dialog-title {
  color: var(--gold);
}
