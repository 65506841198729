.session_form {
  width: 100%;
}
.session_form .ant-input {
  height: 35px;
  width: 100%;
}
.session_form .ant-btn {
  margin-top: 10px;
  height: 50px;
  width: 82%;
  text-transform: uppercase;
  font-size: 20px;
  /* font-family: var(--orbitron_font); */
  border: 0 solid var(--gold);
  background: var(--gold);
  color: white;
}
.session_form .ant-form-item-label > label {
  color: white;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.5em;
}
.session_form .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.session_form .ant-btn,
.session_form .ant-btn:focus,
.session_form .ant-btn:active {
  background-color: var(--gold);
  border: 3px solid var(--gold);
}
.session_form .ant-btn:hover {
  background-color: white;
  border: 0 solid var(--gold);
  color: var(--gold);
}


.session_form .session_input_text .ant-input-affix-wrapper {
  border: 1px solid #565656;
  border-radius: .1875rem;
  padding: 15px;
}
.session_form .session_input_text .ant-input-affix-wrapper,
.sesison_form .session_input_text .ant-input-affix-wrapper input {
  background-color: #373737;
  color: #fff;  
}
