.mainContainerBluetooth {
  flex: auto;
  min-height: 0;
}

.itemName {
  font-size: 12px;
  text-align: "center";
  color: #333333;
  padding: 10px;
}

.itemRssi {
  font-size: 10px;
  text-align: "center";
  color: #333333;
  padding: 2px;
}

.itemId {
  font-size: 8px;
  text-align: "center";
  color: #333333;
  padding: 2px;
  padding-bottom: 20px;
}

.safeContainer {
  flex: 1;
  flex-direction: "column";
  margin-top: 18px;
  margin-left: 30px;
}

.trackerId {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 15px;
  color: #d5bb64;
  font-weight: "300";
}

.trackerMessage {
  color: #fff;
  font-size: large;
  font-weight: "400";
  margin-left: auto;
  margin-right: auto;
}

.logoContainer {
  justify-content: "center";
  align-items: "center";
  margin-top: 20px;
  height: 50px;
}
.logoYellow {
  width: 123px;
  height: 130px;
  text-align: center;
}
.greyButtonsContainerPlatform {
  flex: 8;
  text-align: center;
  margin-top: 13px;
}

.pairingButtonContainer {
  text-align: center;
  flex: 1 1;
  margin-top: 14px;
  margin-bottom: 2%;
}

.paragraphData {
  color: #d5bb64;
  font-weight: "400";
  margin-left: initial;
  margin-top: 10px;
}
.pairingMessage {
  color: #fff;
  font-weight: "400";
  margin-top: 10px;
  margin-left: 20px;
}
.pairingButton {
  background-color: #d5bb64;
  justify-content: "center";
  width: 65%;
  height: 50px;
  border-width: 1px;
  border-radius: 4px;
  margin-top: 40px;
  margin-right: 20px;
  z-index: 0.5;
}

.textContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  margin-left: 0%;
}

.deviceButton {
  background-color: blue;
  justify-content: "center";
  width: 87%;
  height: 100px;
  border-width: 1px;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  z-index: 0.5;
}
.pairingButtonLight {
  background-color: #85753f;
  justify-content: "center";
  margin-top: 20px;
  width: 89%;
  height: 50px;
  border-width: 1px;
  padding: 10px;
  border-radius: 4px;
  margin-left: 20px;
  margin-right: 20px;
}
.pairingButtonText {
  color: #000;
  text-align: "center";
  justify-content: "center";
  font-size: 14px;
  font-weight: "400";
}
.pairingStatusContainer {
  flex: 1.5;
}
.logoFire {
  background-color: #090a0b;
  height: 100px;
  width: 100px;
  border-color: #ffffff;
  border-width: 1px;
  border-radius: 400px / 2;
}
.platformButton {
  flex: 1;
  background-color: #9a9ea6;
  width: 65% !important;
  height: 50px;
  border-width: 1px;
  border-radius: 4px;
  margin-top: 40px;
  margin-right: 20px;
  margin-left: 0px !important;
}
.platformButtonText {
  color: #000;
  text-align: "center";
  justify-content: "center";
  font-size: 14px;
  font-weight: "400";
}
.detailsButtonText {
  color: #000;
  font-size: 14px;
  font-weight: "400";
}

.transferContainer {
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-align: center;
  height: fit-content;
}

.tranfer {
  color: #fff;
  font-size: 22px;
  margin-bottom: 30px;
  text-align: center;
}

.processing {
  color: white;
  font-size: larger;
  margin-bottom: 30px;
}

.finished {
  color: #fff;
  font-size: large;
  margin-bottom: 20px;
  text-align: center;
}

.finishedBlink {
  color: #fff;
  font-size: large;
  margin-bottom: 20px;
  text-align: center;
  /*animation: blinker 1s linear infinite;*/
}
/*
@keyframes blinker {
  0% {
    color: var(--gold);
  }
  49% {
    color: var(--gold);
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: var(--gold);
  }
}*/

.latest {
  font-size: 22px;
  font-weight: "600";
  color: #fff;
  margin-top: 10px;
}

.itemPeripheral {
  background-color: #9a9ea6;
  justify-content: "center";
  width: 92%;
  border-width: 1;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  z-index: 0.5;
}
